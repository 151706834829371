.pack_row {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.pack_row_active {
    background: #D7FDEF;

    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.pack_row:hover {
    background: #F3F3F3;
}

.pack_row td:first-child, .pack_row_active td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.pack_row td:last-child, .pack_row_active td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.pack_row_active td, .pack_row td {
    border-bottom: 3px solid #FFFFFF;
}

.quantity_bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    margin: 0 auto;
    padding: 4px;
    width: fit-content;
    background: #959595;
    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
}

.pack_row td button {
    padding: 8px 16px;
    border-radius: 8px;
    border: none;

    background: #2E89CC;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;

    cursor: pointer;
}

.checked {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}