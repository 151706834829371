.ops_manage_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 8px;

    width: 85%;
}

.ops_manage_body table {
    width: 100%;
    text-align: center;
}

.ops_manage_body thead {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ops_manage_body tbody {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.ops_manage_body th {
    padding: 4px;
}

.ops_manage_btn {
    padding: 8px;

    background: #959595;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;
}

.ops_search_none {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}