.checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;

    border: 2px dashed #959595;
    border-radius: 8px;

    width: 24px;
    height: 24px;

    cursor: pointer;
}

.checkbox_active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;

    background: #DBFFF2;
    border: 2px dashed #48D7A3;
    border-radius: 8px;

    width: 24px;
    height: 24px;

    cursor: pointer;
}