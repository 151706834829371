.row {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.row td:first-child {
    font-size: 16px;
    letter-spacing: 4px;

    padding-left: 8px;
}

.row td:nth-child(2) {
    font-size: 10px;
}

.row td:nth-child(3) {
    text-align: center;
}