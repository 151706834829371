.bills_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 10px;

    width: 100%;
    height: 70vh;
    overflow-y: auto;
}

.bills_body button {
    margin-left: auto;
}

.bills_body table {
    width: 100%;
    text-align: center;
    border-spacing: 0;
}

.bills_body th {
    padding: 8px;
    border: 1px solid #959595;
    background: #959595;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.bills_body th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.bills_body th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.bills_body tbody {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.bills_body_none {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 14px;

    padding-top: 20px;
    text-align: center;
}