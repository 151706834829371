.ops_ticket_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 10px;
    padding: 0px;
    width: 80%;
}

.ops_ticket_question {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_ticket_text {
    align-self: flex-start;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
}

.ops_ticket_inputs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    width: 100%;
}

.ops_ticket_inputs select {
    padding: 8px;

    height: 7vh;

    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 8px;

    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_ticket_inputs select:focus, .ops_ticket_inputs input:focus {
    outline: none;
}

.ops_ticket_inputs button {
    box-sizing: border-box;
    padding: 0px 8px;

    width: 40px;
    height: 46px;

    background: #747474;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-size: 24px;

    cursor: pointer;
}

.ops_ticket_table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.ops_ticket_table table {
    width: 100%;
    border-spacing: 0;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_ticket_table th {
    padding: 8px 40px;
    text-align: center;

    border: 1px solid #959595;
    background: #959595;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ops_ticket_table th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ops_ticket_table th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ops_ticket_sum {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.ops_ticket_sum span {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_ticket_btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 0px;
    gap: 8px;
}

.ops_ticket_btns button {
    padding: 12px 24px;

    border: none;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;
}

.ops_ticket_btns button:first-child {
    opacity: 0.6;

    color: #7D7D7D;
    font-weight: 700;
}

.ops_ticket_btns button:last-child {
    background: #959595;

    color: #FFFFFF;
    font-weight: 400;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    width: 100%;
}