.ops_tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    width: 100%;
}

.ops_tickets_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    width: 100%;
}

.ops_tickets_search {
    display: flex;
    flex-direction: row;
    gap: 24px;

    width: 100%;
}

.ops_tickets_search button {
    min-width: 200px;

    font-size: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
}

.ops_tickets_body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min(400px);
    box-sizing: border-box;
    overflow-y: auto;
    width: 100%;
    height: 55vh;
    gap: 16px;
    padding: 8px;
}

.ops_tickets_none {
    color: #747474;
    font-size: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}