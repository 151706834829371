.ops_change_profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 8px;
    isolation: isolate;

    opacity: 0.5;
    border: 1px solid #FFFFFF;
    border-radius: 8px;

    cursor: pointer;
}

.ops_change_profile:hover, .ops_change_profile_active {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 8px;
    isolation: isolate;

    border: 1px solid #6D6D6D;
    border-radius: 8px;
    opacity: 1.0;

    cursor: pointer;
}

.ops_change_profile span, .ops_change_profile_active span {
    color: #A6A0A0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}