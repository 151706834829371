.ops_cashin_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0px;
    gap: 24px;
}

.ops_cashin_text {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    width: 100%;
}