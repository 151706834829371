.pc_input {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;

    box-sizing: border-box;
    width: 100%;

    background: #FFFFFF;
    border: 1px solid #E9E8E8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.pc_input input{
    text-align: center;
    font-weight: 700;
    font-size: 14px;

    padding: 10px;
    margin-bottom: 14px;

    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #959595;
}

.pc_input input:focus {
    outline: none;
    border-bottom-color: black;
}

.pc_input span:first-child {
    min-width: 30%;
}

.pc_input span{
    text-align: center;
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}