.tickets_nav {
    display: flex;
    flex-direction: row;

    border-bottom: 1px solid #959595;

    width: 100%;
}

.tickets_nav button {
    flex: 1;

    padding: 10px;

    background: #FFFFFF;

    border: none;
    border-bottom: 1px solid #FFFFFF;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    cursor: pointer;
}

.btn_active, .tickets_nav button:hover {
    border-bottom: 1px solid #959595 !important;
    font-weight: 500 !important;
}