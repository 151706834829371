.ops_bill_choosen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 32px;
    width: 100%;
}

.ops_partner_active {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    width: 100%;

    box-sizing: border-box;
}

.ops_partner_pay {
    justify-content: center;

    display: flex;
    flex-direction: row;
    gap: 10px;

    box-sizing: border-box;
}

.ops_partner_texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
}

.ops_partner_texts span:first-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_partner_texts span:last-child {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}

.ops_bill_stats {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px 0px;
    width: 100%;
    box-sizing: border-box;
}

.ops_bill_stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 8px;
    gap: 8px;
}

.ops_bill_stat span:first-child {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_bill_stat span:last-child {
    color: #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}

.ops_bill_stat_input:first-child {
    width: 150px;
    height: 30px;

    background: #FFFFFF;
    border: 1px solid #959595;
    border-radius: 8px;

    font-family: 'Roboto';

    appearance: none;
    background-image: url("../../../assets/body/date_icon.svg");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1em;
}

.ops_bill_comment {
    padding: 8px;
    resize: none;
    box-sizing: border-box;

    width: 100%;
    height: 80px;

    border: 1px solid #959595;
    border-radius: 8px;

    font-family: 'Roboto';
}

.ops_bill_text {
    margin: 0 auto;
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    
    width: 100%;
}