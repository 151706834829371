.navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;

    width: 300px;
    height: 100vh;
}

.navbar_anim {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;

    width: 300px;
    height: 100vh;

    animation: slideIn 0.5s ease-in;
}

@keyframes slideIn {
    0% {width: 75px;}
    100% {width: 300px;}
}

.navbar_closed {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;

    max-width: 300px;
    width: 75px;
    height: 100vh;
}

.navbar_closed_anim {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;

    max-width: 280px;
    width: 75px;
    height: 100vh;

    animation: slideOut 0.5s ease-out;
}

@keyframes slideOut {
    0% {width: 300px;}
    100% {width: 75px;}
}

.navimg {
    margin-left: auto;
    margin-right: 14px;
    cursor: pointer;
}

.nav_logo {
    align-self: center;
    margin: 20px;
    opacity: 1;
}

.nav_logo_anim {
    align-self: center;
    margin: 20px;
    opacity: 1;

    animation: appear 0.7s ease-out;
}

.nav_text {
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    
    opacity: 1;
}

.nav_text_anim {
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    opacity: 1;
    animation: appear 0.7s ease-out;
}

.nav_text_closed_anim {
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    opacity: 1;
    animation: disappear 0.7s ease-out;
}

@keyframes appear {
    0% {opacity: 0;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}

.nav_logo_closed {
    align-self: center;
    margin: 20px;
    opacity: 0;
}

.nav_logo_closed_anim {
    align-self: center;
    margin: 20px;
    opacity: 0;

    animation: disappear 0.2s ease-out;
}

@keyframes disappear {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

.nav_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 3%;
}

.nav_menu_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nav_settings_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nav_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px;
    gap: 24px;

    opacity: 0.4;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;
}

.nav_item:hover, .nav_item_active {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px;
    gap: 24px;

    opacity: 1.0;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;
}