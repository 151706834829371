.ops_safe_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    padding: 0px;
    gap: 32px;

    box-sizing: border-box;
    overflow-y: auto;
    width: 85%;
    height: 60vh;
}

.ops_safe_question {
    margin: 0 auto;
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_safe_text {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
}

.ops_safe_body table {
    align-self: center;
}

.ops_safe_body thead {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}

.ops_safe_body tbody {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_safe_body th {
    text-align: center;
    padding: 4px 32px;
    box-sizing: border-box;
}

.ops_safe_sum {
    display: flex;
    flex-direction: row;
    gap: 8px;

}

.ops_safe_sum span:first-child {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}

.ops_safe_sum span:last-child {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: center;

    width: 100%;
}