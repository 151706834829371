.bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: fit-content;

    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
    user-select: none;
}