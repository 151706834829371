.filter_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    gap: 5px;

    border: 1px solid #D2D2D2;
    border-radius: 8px;

    padding: 8px;

    color: #939393;
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
    line-height: 9px;
}

.filter_item img {
    cursor: pointer;
}