.pcs_body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px;
    gap: 16px;
    width: 100%;
}

.pcs_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
}

.pcs_sum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    background: #F4F4F4;
    border: 1px solid #E9E8E8;
    border-radius: 8px;
}

.pcs_sum span {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
}

.pcs_sum span:nth-child(2) {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
}

.pcs_input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    border: 1px solid #959595;
    border-radius: 12px;
}

.pcs_input span {
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    text-align: center;
    background: #959595;
    padding: 8px;
}

.pcs_input span:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.pcs_input span:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.pcs_input input, .pcs_input input:focus {
    padding: 4px;
    border: none;
    border-radius: 8px;
    outline: none;
    width: 65px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}