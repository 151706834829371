.lds_ring {
    display: inline-block;
    position: absolute;
    top: 40%;
    width: 80px;
    height: 80px;
}

.lds_ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #FFFFFF;
    border-radius: 50%;
    animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds_ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds_ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds_ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds_ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
