.ops_change_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    width: 100%;
}

.ops_change_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 80%;

    color: #959595;
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ops_change_btns {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.ops_change_correction {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 80%;
}

.correction_title {
    color: #959595;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.corrections {
    display: flex;
    flex-direction: row;
    gap: 16px;

    width: 100%;
}

.correction_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.correction_item span {
    color: #5E605F;
    font-family: 'Roboto';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
