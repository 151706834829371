.btn {
    padding: 8px;

    border: none;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;
}