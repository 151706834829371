.summary_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 8px;

    width: 85%;
}

.summary_info {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    height: 70vh;
    overflow-y: auto;
}

.summary_table {
    margin: 0 auto;
    border-spacing: 4px;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.summary_table th {
    font-size: 12px;
    font-weight: 600 !important;
}