.ticket_row td {
    padding: 8px 40px;
    text-align: center;
}

.ticket_row td input {
    width: 25%;
    height: 35px;
    margin: 0px 4px;
    box-sizing: border-box;

    border: none;
    
    text-align: center;

    color: #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}

.ticket_row td input:focus {
    outline: none;
}

.ticket_row td button {
    padding: 8px 8px;

    background: #3269B0;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;
}