.ops_partner_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.ops_partner_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 50%;
}

.ops_partner_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    width: 60%;

    box-sizing: border-box;
}

.ops_partner_text {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_partner_row span:first-child {
    min-width: 30%;
}

.ops_partner_row span:last-child {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    
    width: 100%;
}