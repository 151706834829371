.ops_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
}

.ops_card_details {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.ops_card_details_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.ops_card_name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    color: #747474;
}

.ops_card_desc {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: #747474;
}

.ops_card_back {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;

    opacity: 0.5;

    cursor: pointer;
}

.ops_card_back:hover {
    opacity: 1.0;
}

.ops_card_back span {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}