.ops_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 8px;

    padding: 8px;
    width: 100%;
}

.ops_content_header {
    display: flex;
    align-self: flex-start;
    gap: 8px;
}

.ops_content_header button {
    color: #9D9D9D;
    background-color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    border: 2px solid #FFFFFF;

    padding: 8px 16px;

    cursor: pointer;
}

.ops_content_header button:hover, .ops_nav_active {
    border: 2px solid #9D9D9D !important;
    border-radius: 8px !important;
}

.ops_list_body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    box-sizing: border-box;
    overflow-y: auto;
    width: 100%;
    height: 65vh;
    gap: 8px;
    padding: 8px;
}

.op_content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 8px;
    gap: 16px;
    width: 100%;
}