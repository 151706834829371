.store_icon {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7px 1px;
    gap: 10px;

    width: 50px;
    height: 50px;

    border: 2px solid #FFFFFF;
    border-radius: 9999px;
}

.store_icon span {
    font-weight: 900 !important;
    font-size: 30px !important;
    line-height: 35px !important;
}

.store_icon_small {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    box-sizing: border-box;

    width: 34px;
    height: 34px;

    border: 2px solid #FFFFFF;
    border-radius: 9999px;
}

.store_icon_small span {
    font-weight: 900 !important;
    font-size: 22px !important;
    line-height: 35px !important;
}