.input_container {
    position: relative;
    box-sizing: border-box;
    display: flex;
}

.input_container label {
    position: absolute;
    top: 8px;
    left: 10px;

    padding: 2px;

    font-size: 12px;
    color: #959595;
    transition: all 0.1s ease-in-out;

    background: #FFFFFF;

    user-select: none;
    cursor: text;
}

.input_container input {
    border: 1px solid #959595;
    border-radius: 8px;

    background: transparent;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;

    font-size: 12px;
    color: #747474;
}

.input_container input:focus { 
    border: none;	
    outline: none;
    border: 1px solid #747474;	
}

.input_container input:focus ~ label,
.input_container input:valid ~ label {
    top: -8px;
    font-size: 10px;
}