.ops_tickets_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 16px;

    width: 100%;
}

.ops_tickets_filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    width: 100%;

    margin-right: auto;
}

.ops_tickets_filters_title {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}

.ops_tickets_filters_none {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 9px;
}

.ops_tickets_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    
    margin-left: auto;
}

.info_quantity {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.info_quantity span {
    font-size: 10px !important;
}

.info_value {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.info_value span:last-child {
    font-size: 10px !important;
}

.ops_tickets_ops {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
}

.ops_tickets_ops span {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}

.ops_tickets_ops button {
    padding: 8px 16px;

    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 8px;

    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
}

.ops_tickets_ops button:hover {
    border-color: #2E89CC !important;
    background: #2E89CC !important;
    color: #FFFFFF !important;
}

.operations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    width: 50%;
}

.ops_tickets_table {
    width: 100%;
    height: 49vh;
    overflow-y: auto;
}

.ops_tickets_body table {
    width: 100%;
    border-spacing: 0 8px;
}

.ops_tickets_body th {
    position: sticky;
    top: 0;
    padding: 8px 0;
    border: 1px solid #959595;
    background-color: #959595;
    text-align: left;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
}

.ops_tickets_body th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ops_tickets_body th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tickets_none {
    padding-top: 24px;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;

    text-align: center;
}