.content_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.content_left {
    display: flex;
    flex-direction: row;
    gap: 8px;

    padding-left: 8px; 
}

.content_date {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.date_year {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    opacity: 0.3;
}

.date_month_day {
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
}