.ops_safe_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    box-sizing: border-box;

    gap: 10px;
    padding: 8px;

    width: 90%;
    height: 70vh;

    overflow-y: auto;
}

.ops_safe_question {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_safe_text {
    align-self: flex-start;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
}

.ops_safe_storage {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.ops_safe_storage span {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_safe_check {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.ops_safe_storage input[type="checkbox"] {
    width: 24px;
    height: 24px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border: 2px dashed #959595;
    border-radius: 8px;
    cursor: pointer;
}

.ops_safe_storage input[type="checkbox"]:checked {
    content: url("../../../assets/body/success_icon.svg");
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
}