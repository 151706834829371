/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
@import url(http://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);

body {
  background: #0D2646;
  background-image: -webkit-linear-gradient(right, #0D2646, #082c5b,#0D2646);
  color: white;
  font-family: "Roboto";
  margin: 0;
}
