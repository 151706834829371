.ops_bill_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 0px;
    gap: 8px;

    height: 50vh;
    width: 100%;
    overflow-y: auto;
}

.ops_bill_body table {
    width: 100%;
    border-spacing: 0;
}

.ops_bill_body th {
    padding: 8px;
    border: 1px solid #959595;
    background: #959595;
    text-align: left;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ops_bill_body th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ops_bill_body th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}