.input_container {
  position: relative;
  box-sizing: border-box;
  display: flex;

  border: 1px solid #959595;
  border-radius: 8px;
}

.input_container label {
  position: absolute;
  top: 7px;
  left: 10px;

  padding: 2px;

  font-size: 12px;
  color: #959595;
  transition: all 0.1s ease-in-out;

  background: #FFFFFF;

  user-select: none;
  cursor: text;
}

.input_container input {
  border: none;
  outline: none;

  background: transparent;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;

  font-size: 12px;
  color: #747474;
}

.input_container input:focus { 
  border: none;	
  outline: none;
}

.input_container input:focus ~ label,
.input_container input:valid ~ label {
  top: -8px;
  font-size: 10px;
}

.suffix {
  display: flex;
  align-items: center;

  min-height: 100%;
  background: #F3F3F3;
  position: relative;
  padding: 0 5px;

  color: #959595;
  font-family: "Roboto";
  font-size: 12px;

  border-left: 1px solid #959595;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  user-select: none;
}