.transit_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
}

.transit_item span {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.transit_check {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;

    border: 2px dashed #959595;
    border-radius: 8px;

    width: 24px;
    height: 24px;

    cursor: pointer;
}

.transit_check_active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;

    background: #DBFFF2;
    border: 2px dashed #48D7A3;
    border-radius: 8px;

    width: 24px;
    height: 24px;

    cursor: pointer;
}