.profile_img {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    background-color: #6D6D6D;
    border: 2px solid #FFFFFF;
    border-radius: 999px;

    width: 32px !important;
    height: 32px !important;
}

.profile_img img {
    width: 26px !important;
    height: 26px !important;
}