.profiles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    z-index: 1;
    cursor: pointer;
}

.profiles .profile:first-child {
    z-index: 1;
    margin: 0px -6px;
}