.ops_create_body {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 10px;

    width: 70%;
}

.ops_create_question {
    margin: 0 auto;
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_create_text {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ops_create_stats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
}

.ops_stats_profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 8px;
}

.ops_profile_img {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    background-color: #6D6D6D;
    border: 4px solid #FFFFFF;
    border-radius: 999px;

    width: 80px !important;
    height: 80px !important;
}

.ops_profile_img img {
    width: 65px !important;
    height: 65px !important;
}

.ops_stats_profile button {
    padding: 4px 8px;

    background: #959595;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
}

.ops_stats_profile button:hover {
    background: #2E89CC;
}

.ops_profile_colors {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
}

.ops_stats_inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.ops_stats_inputs select {
    padding: 4px;

    width: 12vw;
    height: 5vh;

    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 8px;

    color: #696969;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.ops_inputs_row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
}