.ops_cashout_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    padding: 0px;
    gap: 8px;
    width: 80%;
}

.ops_cashout_body span {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
}

.ops_input_comment {
    padding: 8px;
    resize: none;
    box-sizing: border-box;

    width: 100%;
    height: 80px;

    border: 1px solid #DDDDDD;
    border-radius: 8px;

    font-family: 'Roboto';
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
}