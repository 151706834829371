.ops_ticket_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 16px;
    padding: 0px;
    width: 85%;
}

.ops_ticket_question {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_ticket_text {
    align-self: flex-start;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
}

.ops_ticket_table {
    height: 50vh;
    overflow-y: auto;
}

.ops_ticket_table table {
    width: 100%;
    border-spacing: 0;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_ticket_table th {
    padding: 8px 40px;
    text-align: center;

    border: 1px solid #959595;
    background: #959595;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ops_ticket_table th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ops_ticket_table th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    width: 100%;
}