.feedback {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 32px 16px;
    gap: 16px;
    box-sizing: border-box;

    position: absolute;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
    border-radius: 8px;

    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;

    animation: slidein 0.3s ease-out;
}

.feedback_out {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 16px;
    gap: 16px;
    box-sizing: border-box;

    position: absolute;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
    border-radius: 8px;

    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;

    animation: slideout 0.3s ease-in;
}

@keyframes slidein {
    0% {transform: translateY(-130px);}
    100% {}
}

@keyframes slideout {
    0% {}
    100% {transform: translateY(-200px);}
}