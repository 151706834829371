.ticket_row td {
    padding: 8px 40px;
    text-align: center;
    width: 20%;

    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ticket_row td > img {
    cursor: pointer;
}

.ticket_row select {
    background: #FFFFFF;

    padding: 8px;

    border: 1px solid #DDDDDD;
    border-radius: 8px;
}

.ticket_row input {
    padding: 8px;
    box-sizing: border-box;

    border: 1px solid #DDDDDD;
    border-radius: 8px;
}

.ticket_row td:nth-child(2) input, .ticket_row td:nth-child(3) input {
    width: 12vw;
}

.ticket_row td:nth-child(4) input {
    width: 3vw;
}

.ticket_row input:focus, .ticket_row select:focus {
    outline: none;
}