.ops_perm_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    gap: 10px;
    padding: 14px;
    box-sizing: border-box;

    width: 80%;
    height: 70vh;
    overflow-y: auto;
}

.ops_perm_body span {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ops_perm_stores {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}

.ops_perm_search {
    padding: 8px;
    box-sizing: border-box;
    width: 100%;

    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 8px;

    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.ops_perm_search:focus {
    outline: none;
}

.ops_perm_body table {
    width: 100%;
}

.ops_perm_body thead {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    text-align: left;
}

.ops_perm_body tbody {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
}