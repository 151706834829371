.ops_pay_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 40px;

    width: 85%;
}

.ops_pay_text {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_pay_choose {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.ops_pay_cash, .ops_pay_transfer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px;
    
    border: 2px solid #959595;
    border-radius: 8px;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;
}

.ops_pay_cash:hover, .ops_pay_transfer:hover {
    background: #0D2646;
    color: white;
    border: 2px solid white;
}

.ops_pay_cash:hover > img {
    content: url("../../../assets/body/ops_bill_cash_2.svg");
}

.ops_pay_transfer:hover > img {
    content: url("../../../assets/body/ops_bill_transfer_2.svg");
}

.cash_choosen, .transfer_choosen {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px;
    
    background: #0D2646;
    border: 2px solid white;
    border-radius: 8px;

    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    cursor: pointer;
}

.cash_choosen > img {
    content: url("../../../assets/body/ops_bill_cash_2.svg");
}

.transfer_choosen > img {
    content: url("../../../assets/body/ops_bill_transfer_2.svg");
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    width: 100%;
}