.ops_ticket_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 12px;

    width: 70%;
}

.image {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.no_img {
    width: 280px;
    height: 210px;

    border-radius: 8px;
    border: 1px solid #747474;
}

.info_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 8px;

    width: 100%;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.info_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    width: 100%;

    color: #747474;
    font-size: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.head_title {
    display: flex;
    align-items: center;

    padding: 8px;
    width: 100%;

    border-radius: 8px;
    background: #959595;

    color: #FFF;
    font-size: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    box-sizing: border-box;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
}