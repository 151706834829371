.ops_tickets_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 16px;

    width: 85%;
}

.ops_tickets_pack {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 16px;

    padding: 8px;

    width: 100%;
    height: 35vh;
    overflow-y: auto;
}

.ops_tickets_text {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_tickets_comment {
    padding: 8px;
    resize: none;

    width: 70%;
    height: 80px;
    box-sizing: border-box;

    border: 1px solid #959595;
    border-radius: 8px;

    font-family: 'Roboto';
}

.ops_tickets_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.ops_tickets_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    
    margin-left: auto;
}

.info_quantity {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.info_quantity span {
    font-size: 10px !important;
}

.info_value {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.info_value span:last-child {
    font-size: 10px !important;
}

.ops_tickets_body table {
    width: 100%;
    border-spacing: 0;
}

.ops_tickets_body th {
    padding: 8px 0;
    border: 1px solid #959595;
    background-color: #959595;
    text-align: left;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ops_tickets_body th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ops_tickets_body th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tickets_none {
    padding-top: 24px;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;

    text-align: center;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    width: 100%;
}