@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
@import url(http://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    align-self: center;
}

.head {
    align-self: center;
    margin-top: 5%;
    margin-bottom: 60px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    max-width: 400px;
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 80%;
}

.login span {
    margin-right: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
}

.login_email {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: none;
    padding: 5%;
    box-sizing: border-box;
}

.login_pass {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: none;
    padding: 5%;
    box-sizing: border-box;
}

.login_email_anim {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: none;
    padding: 5%;
    box-sizing: border-box;

    animation: slideInEmail 1.2s ease-in;
}

.login_pass_anim {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: none;
    padding: 5%;
    box-sizing: border-box;

    animation: slideInPass 1.2s ease-in;
}

.login_pass_err, .login_email_err {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: none;
    padding: 5%;
    box-sizing: border-box;
    box-shadow: 0 0 20px red;
}

.login_pass_err:focus, .login_email_err:focus {
    outline: none;
}

.login_email:hover,
.login_email:focus,
.login_email_anim:hover,
.login_email_anim:focus,
.login_pass:hover,
.login_pass:focus,
.login_pass_anim:hover,
.login_pass_anim:focus {
    box-shadow: 0 0 10px whitesmoke;
    outline: none;
}

@keyframes slideInEmail {
    0% {transform: translateX(2000px);}
    100% {}
}

@keyframes slideInPass {
    0% {transform: translateX(-2000px);}
    100% {}
}

::placeholder{
    color:#1d559a;
    opacity: 20%;
}

.loginctacontent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5%;
}

.loginctacontent button {
    background: #235696;
    color: white;
    border: none;
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 12px 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}

.loginctacontent button:hover {
    box-shadow: 0 0 5px whitesmoke;
    background-color: #2e6cb8;
    cursor: pointer;
}

/* Animations */

.login_text {
    opacity: 80%;
    animation: slideIn_login_text 1.8s ease-in;
}

@keyframes slideIn_login_text {
    0% {transform: translateY(-2000px);}
    100% {}
}

.login_cta_button {
    animation: slideIn_login_cta_button 2s ease-in;
}
  @keyframes slideIn_login_cta_button {
    0% {transform: translateY(-2000px);}
    100% {}
}

.login_remined_password {
    text-decoration-line: none;
    color: white;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 12px;
    opacity: 30%;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;

    animation: slideIn_login_remined_password 2.2s ease-in;
}
@keyframes slideIn_login_remined_password {
    0% {transform: translateY(-2000px);}
    100% {}
}

.login_remined_password:hover{
    opacity: 100%;
}

.logo {
    animation: slideIn_logo 2.5s ease-in;
}
@keyframes slideIn_logo {
    0% {transform: translateY(-300px);}
    100% {}
}

/**
.big_logo {
    animation: fadeIn_big_logo 3s ease;
}

@keyframes fadeIn_big_logo {
    0% {opacity: 0%;}
    80% {opacity: 0%;}
    100% {opacity: 100%}
}
**/