.day_open_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.day_open_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.day_open_img {
    display: flex;
    align-items: center;
    background: #284161;
    border: 1.5px solid #FFFFFF;
    border-radius: 999px;
    padding: 10px;
    box-sizing: border-box;
}

.day_open_img img {
    width: 44px !important;
    height: 44px !important;
}

.day_open_header img {
    width: 64px;
    height: 64px;
}

.day_open_text {
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;

    color: rgba(255, 255, 255, 0.3);
}

.money_sum {
    font-weight: 700;
    font-size: 80px;
    line-height: 94px;
    color: #FC985F;
    opacity: 0.8;
}

.day_open_subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.day_open_prev {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    opacity: 0.2;

    cursor: pointer;
}

.day_open_prev_text {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}

.day_open_prev:hover {
    opacity: 1;
}

.day_open_diff {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    opacity: 0.2;
}

.daily {
    margin: 100px 0;
}

.day_open_pcs_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 48px 0px 24px;
}

.day_open_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.day_open_pc {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.day_open_pc_sum {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    opacity: 0.4;
}

.day_open_item input {
    width: 56px;
    height: 48px;
    box-sizing: border-box;

    border: 1px solid #FFFFFF;
    border-radius: 8px;
    
    background: #0D2646;
    text-align: center;

    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}

.day_open_cta_content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.btn_save {
    background-color: #0D2646;
    color: white;
    padding: 10px;

    width: 149px;
    height: 48px;

    font-weight: 700;

    border: 1px solid white;
    border-radius: 8px;

    cursor: pointer;
}

.btn_back {
    background-color: #0D2646;
    color: white;
    padding: 10px;

    width: 149px;
    height: 48px;

    font-weight: 700;

    border: 1px solid white;
    border-radius: 8px;

    cursor: pointer;
}