.tickets_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 10px;

    width: 70%;
    height: 70vh;
    overflow-y: auto;
}

.tickets_body button {
    margin-left: auto;
}

.ticket_table {
    width: 100%;
    border-spacing: 0;
}

.ticket_table th {
    padding: 8px;
    border: 1px solid #959595;
    background-color: #959595;
    text-align: left;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ticket_table th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ticket_table th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ticket_table_none {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 14px;

    padding-top: 20px;
    text-align: center;
}