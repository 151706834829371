.ticket_row {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.ticket_row_active {
    background: #D7FDEF;

    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.ticket_row:hover {
    background: #F3F3F3;
}

.ticket_row td:first-child, .ticket_row_active td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ticket_row td:last-child, .ticket_row_active td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ticket_row_active td, .ticket_row td {
    border-bottom: 3px solid #FFFFFF;
}

.badcashout {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 4px 12px;
    width: fit-content;
    margin: 0 auto;

    border-radius: 8px;

    background: #F6525C;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}

.badcashout img {
    cursor: pointer;
}