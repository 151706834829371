.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    height: 8vh;
    padding: 4px 8px;

    border: 0.5px solid #959595;
    border-radius: 8px;
}

.item_receipt {
    display: flex;
    align-items: center;

    padding: 4px;

    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.item_date {
    flex: 1;
    color: #5E605F;
    font-family: 'Roboto';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.item_value {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 4px;

    color: #5E605F;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.item_value span:nth-child(2) {
    font-size: 10px;
    font-weight: 400;
}