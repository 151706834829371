.ops_ticket_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 10px;
    padding: 0px;
    width: 90%;
}

.ops_ticket_text {
    align-self: flex-start;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
}

.ops_ticket_question {
    margin: 0 auto;
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_ticket_inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    
    width: 100%;
}

.ops_ticket_inputs select {
    background: #FFFFFF;

    padding: 8px;
    height: 46px;
    width: 20%;

    border: 1px solid #DDDDDD;
    border-radius: 8px;

    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

     /* for the dropdown indicator */
     appearance: none;
     background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
     background-repeat: no-repeat;
     background-position: right 0.5rem center;
     background-size: 1em;
}

.ops_ticket_inputs input:nth-child(2), .ops_ticket_inputs input:nth-child(3){
    width: 15%;
}

.ops_ticket_inputs button {
    box-sizing: border-box;
    padding: 0px 8px;

    width: 40px;
    height: 46px;

    background: #2E89CC;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-size: 24px;

    cursor: pointer;
}

.ops_ticket_inputs button:hover {
    background: #0D2646;
}

.ops_ticket_table {
    width: 100%;
    border-spacing: 0;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_ticket_table th {
    padding: 8px 40px;
    text-align: center;

    border: 1px solid #959595;
    background: #959595;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ops_ticket_table th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ops_ticket_table th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    width: 100%;
}