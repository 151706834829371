.store_item {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 10px;
}

.store_detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    gap: 10px;
    box-sizing: border-box;

    min-width: 250px;
    max-width: 390px;
    width: 32vw;

    min-height: 60px;
    height: 11vh;

    border: 1px solid #FFFFFF;
    border-radius: 8px;
}

.store_detail:hover {
    background: #19375E;
}

.store_info {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    color: #FFFFFF;
    text-transform: uppercase;
}

.store_open {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    color: #FFFFFF;
    text-decoration: none;
}