td {
    padding: 4px;
}

.user_btns {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: center;
    gap: 8px;
}

.user_btns button {
    padding: 4px 8px;

    background: #959595;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
}

.user_btns button:hover {
    background: #2E89CC;
}