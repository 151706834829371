.ops_change_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    width: 100%;
}

.ops_change_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 80%;

    color: #959595;
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ops_change_btns {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.ops_change_profiles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 100%;
}

.ops_change_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
}

.ops_change_login span {
    color: #6D6D6D;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}

.ops_change_login input, .ops_change_login input:focus {
    padding: 8px;
    outline: none;
    border: 1px solid #6D6D6D;
    border-radius: 8px;
    width: 30%;
}