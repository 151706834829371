.ops_list_element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 8px;
    width: 295px;
    height: 90px;

    background: #F4F4F4;
    border: 1px solid #E9E8E8;
    border-radius: 8px;

    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: clamp(9px, 1vw, 10px);
    line-height: clamp(10px, 1vw, 12px);
    text-align: center;

    cursor: pointer;
}

.ops_list_element:hover {
    background: #FFFFFF;
    border: 1px solid #E9E8E8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}