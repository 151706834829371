.ticket_row td {
    padding: 8px;
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.ticket_row:hover {
    background: #F3F3F3;
}

.ticket_row td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ticket_row td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ticket_row button {
    padding: 4px 8px;

    background: #CBCBCB;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
}

.ticket_row button:hover {
    background: #2E89CC;
}