.ops_change_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    box-sizing: border-box;

    overflow-y: auto;
    height: 70vh;
}

.ops_change_body span {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_change_ops {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.ops_change_file {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    border: 1px solid #DDDDDD;
    border-radius: 8px;
}

.ops_file_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    height: 100%;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    background: #747474;

    cursor: pointer;
}

.ops_file_text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
}

.ops_file_text span {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.ops_file_exit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;

    cursor: pointer;
}

.ops_file_upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 10px;

    background: #2E89CC;
    border: none;
    border-radius: 8px;
    opacity: 0.4;

    cursor: pointer;
}

.ops_file_upload:hover {
    opacity: 1;
}

.ops_file_upload span {
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}