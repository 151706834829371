.comment_bubble {
    color: #FFFFFF;
    background: #2E89CC;
    text-align: left;

    position: absolute;

    margin-top: 8px;
    padding: 10px;

    border-radius: 10px;
    max-width: 220px;
}

.profile_bubble {
    color: #FFFFFF;
    background: #2E89CC;

    position: absolute;

    margin-top: 38px;
    padding: 8px;

    border-radius: 10px;
    max-width: 220px;
}