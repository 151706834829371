.items {
    display: flex;
    flex-direction: row;
    gap: 15px;

    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 9px;
}

.item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}