.ops_safe_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    padding: 0px;
    gap: 16px;

    width: 60%;
}

.ops_safe_question {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.ops_safe_text {
    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
}