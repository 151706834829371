.ops_change_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    width: 100%;
}

.ops_change_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 80%;

    color: #959595;
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ops_change_btns {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.ops_change_sum {
    display: flex;
    flex-direction: row;

    width: 80%;
}

.nav_itemized {
    display: flex;
    flex-direction: row;

    border-bottom: 0.5px solid #959595;

    width: 80%;
}

.nav_itemized button {
    flex: 1;

    padding: 5px;

    background: #FFFFFF;

    border: none;
    border-bottom: 0.5px solid #FFFFFF;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;

    cursor: pointer;
}

.nav_itemized button:hover {
    border-bottom: 0.5px solid #959595 !important;
}

.table_itemized {
    display: flex;
    justify-content: center;
    width: 80%;
    height: 39vh;
    overflow-y: auto;
}

.table_itemized table {
    width: 100%;
    text-align: center;
    border-spacing: 0 8px;
}

.table_itemized th {
    position: sticky;
    top: 0;

    padding: 8px;
    border: 1px solid #959595;
    background: #959595;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
}

.table_itemized th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.table_itemized th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.table_itemized tbody {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    gap: 10px;
}