.day_open_ok_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 48px 24px;
    gap: 32px;
}

.day_open_question {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    opacity: 0.2;
}

.day_open_sum {
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    color: #FFFFFF;
    opacity: 0.99;
}

.btn_save {
    background-color: #0D2646;
    color: white;
    padding: 10px;

    width: 193px;
    height: 50px;

    font-weight: 700;

    border: 1px solid white;
    border-radius: 8px;

    cursor: pointer;
}

.btn_back {
    background-color: #0D2646;
    color: white;
    padding: 10px;

    width: 193px;
    height: 50px;

    font-weight: 700;

    border: 1px solid white;
    border-radius: 8px;

    cursor: pointer;
}