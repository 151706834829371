.ticket_card {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ticket_card img {
    border-radius: 8px;

    width: 280px;
    height: 210px;

    cursor: pointer;
}

.no_img {
    width: 280px;
    height: 210px;

    border-radius: 8px;
    border: 1px solid #747474;

    cursor: pointer;
}

.card_name {
    color: #747474;
    font-size: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.card_value {
    display: flex;
    flex-direction: row;
    gap: 4px;

    color: #747474;
    font-size: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card_value span:nth-child(2),  .card_value span:nth-child(3){
    font-weight: 600;
}

.card_dates {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    width: 100%;
}

.date {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 50%;

    box-sizing: border-box;

    border-radius: 8px;
    border: 1px solid #747474;

    color: #747474;
    text-align: center;
    font-size: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.date span:nth-child(2) {
    text-align: center;
    font-size: 13px;
    font-weight: 700;
}