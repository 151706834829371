.bill_row {
    width: 100%;
    background: #FFFFFF;
}

.bill_row:hover {
    background: #F3F3F3;
}

.bill_row button {
    margin: 2px 0;
    padding: 4px 8px;

    background: #CBCBCB;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
}

.bill_row button:last-child {
    margin-left: 4px;
}

.bill_row button:hover {
    background: #2E89CC;
}

.bill_row td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.bill_row td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}