.ok {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;

    border: 1px dashed #959595;
    border-radius: 9999px;

    width: 24px;
    height: 24px;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
    user-select: none;
}

.ok_checked {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;

    width: 24px;
    height: 24px;

    background: #DBFFF2;
    border: 1px dashed #48D7A3;
    border-radius: 9999px;
    cursor: pointer;
}