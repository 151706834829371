.partner_row {
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.partner_row:hover {
    background: #F4F4F4;
    border-radius: 8px;
}

.partner_row td {
    padding: 8px;
}

.partner_row button {
    padding: 4px 8px;

    background: #959595;
    border: none;
    border-radius: 8px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;
}

.partner_row button:hover {
    background: #2E89CC;
}

.ops_partner_icon {
    width: 40px;
    height: 40px;

    background: #FFFFFF;
    border: 2px solid #E9E7E7;
    border-radius: 999px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #BEBEBE;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
}

.ops_bill_cash, .ops_bill_transfer {
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 8px;

    padding: 4px;

    opacity: 0.2;
}

.ops_bill_cash_active, .ops_bill_transfer_active {
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 8px;

    padding: 4px;
}

.ops_bill_cash_active:hover, .ops_bill_cash_choosen {
    content: url("../../assets/body/ops_bill_cash_2.svg");

    background: #0D2646;
    border: 1px solid #F4F4F4;
    border-radius: 8px;

    padding: 4px;
}

.ops_bill_transfer_active:hover, .ops_bill_transfer_choosen {
    content: url("../../assets/body/ops_bill_transfer_2.svg");

    background: #0D2646;
    border: 1px solid #F4F4F4;
    border-radius: 8px;

    padding: 4px;
}