.operation_imgs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
}

.operation_imgs .operation_img:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    padding: 8px;
    border-radius: 999px;

    z-index: 1;
    margin: 0px -8px;
}

.operation_imgs .operation_img:last-child {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px;
    border-radius: 999px;
}

.operation_img {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px;
    border-radius: 999px;
}

.operation_img img {
    width: 16px !important;
    height: 16px !important;
}