.ops_change_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    width: 100%;
}

.ops_change_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 80%;

    color: #959595;
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ops_change_btns {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.ops_change_sum {
    display: flex;
    flex-direction: row;

    width: 80%;
}

.nav_itemized {
    display: flex;
    flex-direction: row;

    border-bottom: 0.5px solid #959595;

    width: 80%;
}

.nav_itemized button {
    flex: 1;

    padding: 5px;

    background: #FFFFFF;

    border: none;
    border-bottom: 0.5px solid #FFFFFF;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;

    cursor: pointer;
}

.nav_itemized button:hover {
    border-bottom: 0.5px solid #959595 !important;
}

.ops_change_itemized {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 80%;
}

.itemized_quantity {
    color: #959595;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.itemized {
    display: flex;
    flex-direction: row;
    gap: 16px;

    padding: 8px;

    width: 100%;
    height: 32vh;

    overflow-y: auto;
}

.itemized_log {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 50%;
    height: 100%;
}

.itemized_table {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 50%;
    height: 100%;
}