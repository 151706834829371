.progress_bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 80%;
}

.step {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;

    padding: 8px;
    border-bottom: 0.5px solid #959595;
    opacity: 0.5;
}

.step_active {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;

    padding: 8px;
    border-bottom: 2px solid #959595;
}

.step_actived {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;

    padding: 8px;
    border-bottom: 0.5px solid #959595;
}

.step span:first-child, .step_active span:first-child, .step_actived span:first-child {
    color: #959595;
    font-family: 'Roboto';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.step span:nth-child(2), .step_active span:nth-child(2), .step_actived span:nth-child(2) {
    color: #959595;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}