.pcs_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.pc_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.pc {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.pc_sum {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    opacity: 0.4;
}

.pc_item input {
    width: 56px;
    height: 48px;

    border: 1px solid #FFFFFF;
    border-radius: 8px;
    
    background: #0D2646;
    color: #FC985F;
    text-align: center;

    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}