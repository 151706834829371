.traffic_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    width: 100%;
}

.traffic_value {
    flex: 1;
    color: #2E89CC;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}