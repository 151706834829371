@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&family=Roboto:ital,wght@1,500&display=swap');

.ops_dayclose_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 48px 24px;
    gap: 32px;
    width: 80%;
}

.ops_dayclose_body span {
    color: #A8A8A8;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    text-align: center;
}

.ops_dayclose_body button:nth-child(2) {
    background: #FFFFFF;
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    padding: 16px;
    border: 1.5px solid #747474;
    border-radius: 8px;

    cursor: pointer;
}

.ops_dayclose_body button:nth-child(3) {
    background: #FFFFFF;
    color: #747474;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    border: none;

    cursor: pointer;
}

.dayclose_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 48px 24px;
    gap: 32px;
}

.dayclose_content img {
    padding: 10px;
    width: 64px;
    height: 64px;
    box-sizing: border-box;
    border: 2px solid #FFFFFF;
    border-radius: 999px;
}

.dayclose_content span {
    color: #FFFFFF;
    font-family: 'Reenie Beanie';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
}

.dayclose_content button {
    border: 1.5px solid #FFFFFF;
    border-radius: 8px;
    background: none;
    padding: 16px;

    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    cursor: pointer;
}

.dayclose_content button:last-child {
    border: none;
    font-weight: 400;
    padding: 0;
}

.progbar {
    display: flex;
    justify-content: center;

    width: 100%;
}