.ops_ticket_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 12px;

    width: 70%;
}

.ops_ticket_table table {
    width: 100%;
    border-spacing: 0;
}

.ops_ticket_table th {
    position: sticky;
    top: 0;
    padding: 8px 0;
    border: 1px solid #959595;
    background-color: #959595;
    text-align: left;

    color: #FFF;
    font-size: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ops_ticket_table th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ops_ticket_table th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.prize_code {
    outline: none;
    border: none;

    width: 1.3vw;
    margin-left: 8px;
    text-align: center;

    box-sizing: border-box;

    border-bottom: 2px solid #959595;

    font-weight: 800;
}

.prize_value {
    outline: none;
    border: 1px solid #D2D2D2;
    border-radius: 8px;

    width: 7vw;
    height: 28px;
    padding: 8px;

    box-sizing: border-box;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
}