.tickets_nav {
    display: flex;
    flex-direction: row;

    border-bottom: 1px solid #959595;

    width: 100%;
}

.tickets_nav button {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    padding: 10px;

    background: #FFFFFF;

    border: none;
    border-bottom: 1px solid #FFFFFF;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    cursor: pointer;
}

.tickets_nav button:hover, .btn_active {
    border-bottom: 1px solid #959595 !important;
    font-weight: 700 !important;
}

.tickets_nav button span:nth-child(2) {
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
    line-height: 9px;
}