.summary_content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    width: 100%;
    text-align: center;

    color: #959595;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

.summary_content table {
    margin: 0 auto;
    border-spacing: 0;
}

.summary_content tbody td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.summary_content tbody td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.summary_content tbody tr:hover {
    background: #F3F3F3;
}

.summary_content th, td {
    padding: 10px;
}