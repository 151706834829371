.ops_bill_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 8px;

    width: 85%;
}

.ops_modify_pay {
    justify-content: center;

    display: flex;
    flex-direction: row;
    gap: 10px;

    box-sizing: border-box;
}

.ops_modify_pay img {
    cursor: pointer;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;

    width: 100%;
}