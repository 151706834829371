/* home.css */

.home_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
}

.stores {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.stores_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.store_search {
    width: 500px;
    height: 32px;
    padding: 10px 16px;
    box-sizing: border-box;

    background: #19375E;
    color: #FFFFFF;
    border: 1px solid #547095;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.store_search::placeholder {
    color: #FFFFFF;
}

.store_search:focus {
    box-shadow: 0 0 10px whitesmoke;
    outline: none;
}


/* style.css */

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 1%;
    overflow: hidden;
    box-sizing: border-box;
}

.content_body {
    width: clamp(500px, 100%, 1300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.content_body_anim {
    width: clamp(500px, 100%, 1300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    animation: slideIn .5s ease-out;
}

@keyframes slideIn {
    0% {transform: translateX(100vw);}
    100% {}
}

.content_body_exit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    animation: slideOut .5s ease-out;
}

@keyframes slideOut {
    0% {}
    100% {transform: translateX(-100vw);}
}